import React from 'react'
import './Footer.module.scss'

// TODO: update this Footer to design system
const Footer = () => (
  <div className='footer-wrapper'>
    <div className='footer__item footer__insurance'>
      <div className='footer__insurance--item' id='one'>
        <div className='title_bold'>Asuransi Mobil</div>
        <a className='text-blue' href='/asuransi/mobil/' title='Asuransi Mobil Terbaik'>Asuransi Mobil Terbaik</a>
        <a className='text-blue' href='/asuransi/all-risk/' title='Asuransi Mobil All Risk'>Asuransi Mobil All Risk</a>
        <a className='text-blue' href='/asuransi/tlo/' title='Asuransi Mobil TLO'>Asuransi Mobil TLO</a>
        <a className='text-blue' href='/asuransi/mobil-syariah/' title='Asuransi Mobil Syariah'>Asuransi Mobil Syariah</a>
      </div>
      <div className='footer__insurance--item' id='two'>
        <div className='title_bold'>Asuransi Kesehatan</div>
        <a className='text-blue' href='/asuransi/kesehatan/' title='Asuransi Kesehatan Terbaik'>Asuransi Kesehatan Terbaik</a>
        <a className='text-blue' href='/asuransi/kesehatan-keluarga/' title='Asuransi Kesehatan Keluarga'>Asuransi Kesehatan Keluarga</a>
        <a className='text-blue' href='/asuransi/kesehatan-orangtua/' title='Asuransi Kesehatan Orang Tua'>Asuransi Kesehatan Orangtua</a>
        <a className='text-blue' href='/asuransi/penyakit-kritis/' title='Asuransi Penyakit Kritis'>Asuransi Penyakit Kritis</a>
        <a className='text-blue' href='/asuransi/hospital-cash-plan/' title='Asuransi Cash Plan'>Asuransi Cash Plan</a>
        <a className='text-blue' href='/asuransi/melahirkan/' title='Asuransi Melahirkan'>Asuransi Melahirkan</a>
        <a className='text-blue' href='/asuransi/rawat-jalan/' title='Asuransi Rawat Jalan'>Asuransi Rawat Jalan</a>
        <a className='text-blue' href='/asuransi/gigi/' title='Asuransi Gigi'>Asuransi Gigi</a>
        <a className='text-blue' href='/asuransi/kesehatan-syariah/' title='Asuransi Kesehatan Syariah'>Asuransi Kesehatan Syariah</a>
      </div>

      <div className='footer__insurance--item' id='four'>
        <div className='title_bold'>Asuransi Lainnya</div>
        <a className='text-blue' href='/asuransi/motor/' title='Asuransi Motor'>Asuransi Motor</a>
        <a className='text-blue' href='/asuransi/karyawan/' title='Asuransi Karyawan'>Asuransi Karyawan</a>
      </div>

      <div className='footer__insurance--item' id='five'>
        <div className='title_bold'>Asuransi Berdasarkan&#10;Merek Kendaraanmu</div>
        <a className='text-blue' href='/asuransi/mobil-toyota/' title='Asuransi Mobil Toyota'>Asuransi Mobil Toyota</a>
        <a className='text-blue' href='/asuransi/mobil-honda/' title='Asuransi Mobil Honda '>Asuransi Mobil Honda </a>
        <a className='text-blue' href='/asuransi/mobil-suzuki/' title='Asuransi Mobil Suzuki '>Asuransi Mobil Suzuki </a>
        <a className='text-blue' href='/asuransi/mobil-nissan/' title='Asuransi Mobil Nissan '>Asuransi Mobil Nissan </a>
        <a className='text-blue' href='/asuransi/mobil-mazda/' title='Asuransi Mobil Mazda '>Asuransi Mobil Mazda </a>
        <a className='text-blue' href='/asuransi/mobil-wuling/' title='Asuransi Mobil Wuling '>Asuransi Mobil Wuling </a>
        <a className='text-blue' href='/asuransi/mobil-daihatsu/' title='Asuransi Mobil Daihatsu '>Asuransi Mobil Daihatsu </a>
        <a className='text-blue' href='/asuransi/mobil-mitsubishi/' title='Asuransi Mobil Mitsubishi '>Asuransi Mobil Mitsubishi </a>
        <a className='text-blue' href='/asuransi/mobil-datsun/' title='Asuransi Mobil Datsun '>Asuransi Mobil Datsun </a>
        <a className='text-blue' href='/asuransi/mobil-kia/' title='Asuransi Mobil Kia '>Asuransi Mobil Kia </a>
      </div>
    </div>
    <div className='footer__item__info'>
      <div className='footer__item__group'>
        <div className='footer__item--one'>
          <img alt='Lifepal' src='/static/images/lifepal-logo-blue-svg.svg' />
          <div className='title_bold'>PT Anugrah Atma Adiguna</div>
          <p className='company__address'>CIBIS 9 17th Floor jl TB Simatupang No.2 Cilandak Timur, Pasar Minggu Jakarta Selatan 12560</p>
          <a href='https://www.google.com/maps/@-6.2948158,106.8153824,19z'><button className='heading-sm-r button-blue'><img alt='location' loading='lazy' src='/static/images/icons/location_outline_blue.svg' /><span>Google Maps</span></button></a>
          <div className='title_bold margin-top--lg ojk-title'>Berizin dan diawasi OJK</div>
          <img alt='OJK' loading='lazy' src='/static/images/logo/ojk.svg' />
        </div>
        <div className='footer__item--two'>
          <div className='title_bold'>Layanan Customer Support</div>
          <div className='button__row'>
            <div>
              <div className='subtitle'>WA (Chat only)</div>
              <a href='https://api.whatsapp.com/send/?phone=6282330030027&text=Halo%20Lifepal%2C%20saya%20memiliki%20pertanyaan%20tentang%20produk%20asuransi'><button className='heading-sm-r button-blue'><img alt='whatsapp' loading='lazy' src='/static/images/icons/whatsapp_outline_blue.svg' /><span>0823 300 300 27</span></button></a>
            </div>
            <div>
              <div className='subtitle'>Email</div>
              <a href="mailto:support@lifepal.co.id"><button className='heading-sm-r button-blue '><img alt='email' loading='lazy' src='/static/images/icons/email_outline_blue.svg' /><span>Support@lifepal.co.id</span></button></a>
            </div>
            <div className>
              <div className='subtitle'>Phone</div>
              <a href="tel:622131116121"><button className='heading-sm-r button-blue '><img alt='phone' loading='lazy' src='/static/images/icons/phone_outline_blue.svg' /><span>021 3111 6121</span></button></a>
            </div>
          </div>

          <div className='operational__time'>
            Waktu operasional
            <div className='operational__schedule-table'>
              <div className='operational__day'>Senin - Jumat<br />08.00 - 19.00 WIB</div>
              <div className='operational__day'>Sabtu<br />08.00 - 17.00 WIB</div>
              <div className='operational__day'>Hari libur nasional<br />Libur</div>
            </div>
          </div>

          <div className='title_bold margin-top--lg'>Social Media</div>
          <div className='button__row'>
            <a href='https://www.facebook.com/lifepal'><button className='heading-sm-r button-blue'><img alt='facebook' loading='lazy' src='/static/images/icons/facebook_outline_blue.svg' /><span>Facebook</span></button></a>
            <a href='https://www.instagram.com/lifepal'><button className='heading-sm-r button-blue'><img alt='instagram' loading='lazy' src='/static/images/icons/instagram_outline_blue.svg' /><span>Instagram</span></button></a>
            <a href='https://www.youtube.com/channel/UC-14O1IUXJ_m5t8PRAfWm5w'><button className='heading-sm-r button-blue'><img alt='youtube' loading='lazy' src='/static/images/icons/youtube_outline_blue.svg' /><span>Youtube</span></button></a>
          </div>
          <div className='title_bold margin-top--lg'>Layanan Pengaduan Konsumen</div>
          <div>
            <div className='subtitle'>Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga, Kementerian Perdagangan Republik Indonesia.</div>
            <a href="tel:6285311111010"><button className='heading-sm-r button-blue'><img alt='whatsapp' loading='lazy' src='/static/images/icons/whatsapp_outline_blue.svg' /><span>0853-1111-1010</span></button></a>
          </div>

        </div>
        <div className='footer__item--three'>
          <div className='title_bold'>Metode Pembayaran & Program Cicilan 0% </div>
          <div className='payment-methods-wrapper'>
            <img alt='Metode Pembayaran' loading='lazy' src='/static/images/logo/payment_partners_new_landing.svg' />
            <div className='button__row margin-top--sm'>
              <a href='/ketentuan'><button className='heading-sm-r button-blue'>Syarat & Ketentuan</button></a>
              <a href='/privasi'><button className='heading-sm-r button-blue'>Kebijakan Privasi</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='footer__copyright'>
      <span className='copyright'>Copyright © 2024 Lifepal -- Dikelola oleh PT Anugrah Atma Adiguna, pialang asuransi berizin dan diawasi oleh OJK sesuai KEP-018/KMK.17/1992 dan anggota APPARINDO 060-2001/APPARINDO/2024. Lifepal berusaha untuk menyajikan informasi yang akurat dan terbaru namun dapat berbeda dari informasi yang diberikan oleh penyedia layanan / institusi keuangan. Keseluruhan informasi diberikan tanpa jaminan, kami menyarankan untuk melakukan verifikasi sebelum melakukan keputusan finansial Anda.</span>
    </div>
  </div>
);

export default Footer;